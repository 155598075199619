import type { SiteSiteMetadata } from '@generated/graphql'
import { graphql } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import IframeReader from 'src/components/common/IframeReader'
import StockAvailable from 'src/components/common/StockAvailable/StockAvailable'
import Newsletter from 'src/components/sections/Newsletter'
import { useSession } from 'src/sdk/session'

interface Props {
  data: TAllCmsInstitutional
  location: {
    pathname: string
  }
}

interface TAllCmsInstitutional {
  allCmsInstitutionalPage: TEdges
  site: {
    siteMetadata: SiteSiteMetadata
  }
}

type TEdges = {
  edges: Array<{ node: { sections: Array<{ data: { iframe: string } }> } }>
}

const Page = ({ location, data: { site, allCmsInstitutionalPage } }: Props) => {
  const { locale } = useSession()
  const title = site?.siteMetadata?.title ?? ''
  const sections = allCmsInstitutionalPage?.edges[0]?.node?.sections

  if (!sections.length) {
    return null
  }

  return (
    <>
      <GatsbySeo
        title={title}
        titleTemplate={site?.siteMetadata?.titleTemplate ?? ''}
        description={site?.siteMetadata?.description ?? ''}
        canonical={location.pathname}
        language={locale}
        openGraph={{
          type: 'website',
          title,
          description: site?.siteMetadata?.description ?? '',
        }}
      />
      {sections?.map((section, idx) => (
        <IframeReader key={idx} iframe={section.data.iframe} />
      ))}
      <StockAvailable icon />
      <Newsletter
        title="Recibí promociones y novedades"
        subtitle="Ingresá tu correo electrónico"
      />
    </>
  )
}

export const query = graphql`
  query LandingPageRevistaQuery {
    site {
      siteMetadata {
        titleTemplate
        title
        description
        siteUrl
      }
    }
    allCmsInstitutionalPage(
      filter: { seo: { siteMetadataWithSlug: { slug: { eq: "/revista" } } } }
    ) {
      edges {
        node {
          seo {
            siteMetadataWithSlug {
              slug
            }
          }
          name
          sections {
            data
          }
        }
      }
    }
  }
`

export default Page

export { Head } from 'src/components/common/Head'
