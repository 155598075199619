interface Props {
  iframe: string | null
}

const IframeReader = ({ iframe }: Props) => {
  const codeHTML = {
    __html: iframe ?? '',
  }

  if (!iframe) {
    return null
  }

  return (
    <>
      <div style={{ paddingTop: '16px' }} />
      <div className="iframe__container">
        <div className="iframe__content" dangerouslySetInnerHTML={codeHTML} />
      </div>
    </>
  )
}

export default IframeReader
